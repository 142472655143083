import React, { useState } from 'react'
import { Image, Box } from '@chakra-ui/core'

export default function OriginalImage({ src }){
  const [loaded, setLoaded] = useState(false)
  return <Box bg="white" p={[0, '5px']}>
    <Image src={src} onLoad={() => setLoaded(true)} display={loaded ? 'block' : 'none'} margin="0 auto" />
   {!loaded && <div>loading...</div>}
</Box>
}
