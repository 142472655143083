import React, { useEffect, useState, useCallback } from 'react'
import { Modal, Box, SimpleGrid, ModalOverlay, ModalCloseButton, ModalContent } from "@chakra-ui/core"
import { navigate, useMatch } from '@reach/router'
import Dropzone from './Dropzone'
import OriginalImage from './OriginalImage'
import cl from './cloudinary'


const Thumb = ({ src,...other }) => <Box
  pt="100%"
  background={`url("${src}")`}
  backgroundSize="cover"
  backgroundPosition='center'
  {...other}
/>


export default function Gallery({ thumbs, onThumbClick, originals, onUploadComplete, resources }) {
  const [activeImage, setActiveImage] = useState(null)
  const match = useMatch('/:id')

  useEffect(() => {
    setActiveImage(match ? resources.find(resource => resource.public_id === match.id) : null)
  }, [match, resources])

  function handleModalClose() {
    navigate('/')
  }


  return <Box p={[1, 2]}>
    {/* process.env.NODE_ENV === 'development' && */ <Dropzone onUploadComplete={onUploadComplete} />}
    <SimpleGrid minChildWidth={[100, 200]} gap={[1, 2]}>
      {[...resources].reverse().map(thumb => <Thumb key={thumb.public_id} src={cl.url(thumb.public_id)} onClick={() => onThumbClick(thumb)} />)}
    </SimpleGrid>
    <Modal preserveScrollBarGap isOpen={!!activeImage} onClose={handleModalClose} isCentered >
      <ModalOverlay bg="rgba(0,0,0,0.95)" />
      <ModalContent minWidth="40px" width="auto" maxWidth="auto">
        <ModalCloseButton color="white" position="fixed" cursor="pointer" size={1} p={4} css={{'&:focus': {boxShadow: 'none'}}} />
        {activeImage && <OriginalImage src={cl.url(activeImage.public_id)} />}
      </ModalContent>
    </Modal>
  </Box>
}
