import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Modal,
  Box,
  SimpleGrid,
  ModalOverlay,
  ModalCloseButton,
  Image,
  ModalContent,
  Button,
} from '@chakra-ui/core'
import axios from 'axios'
import { TAG } from './cloudinary'

export default function Dropzone({ onUploadComplete }) {
  const [progress, setProgress] = useState(null)
  async function onDrop(files) {
    const progresses = []
    const logProgress = () => {
      setProgress(
        Math.floor(progresses.reduce((a, b) => a + b) / progresses.length),
      )
    }
    const promises = files.map((file, index) => {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('upload_preset', 'xyozweog')
      fd.append('tags', TAG)
      return axios.post(
        'https://api.cloudinary.com/v1_1/knowyourmome/upload',
        fd,
        {
          onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            progresses[index] = percentCompleted
            logProgress()
          },
        },
      )
    })
    await Promise.all(promises)
    setIsModalOpen(false)
    onUploadComplete()
  }
  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop })
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Box>
      <Button onClick={() => setIsModalOpen(true)}>Upload</Button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay bg="rgba(0,0,0,0.9)" />
        <ModalContent width={['90%', 600]} height={['80vh', 600]}>
          <ModalCloseButton
            color="white"
            position="fixed"
            cursor="pointer"
            size={1}
            p={4}
            css={{ '&:focus': { boxShadow: 'none' } }}
          />
          <Box {...getRootProps()} width="100%" height="100%">
            <input {...getInputProps()} />
            {progress === null && <div>{isDragActive ? 'Drop here' : 'Drag n drop here'}</div>}
            {progress !== null && <div>Uploading...{progress}%</div>}
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}
