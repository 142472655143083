import React, { useEffect, useState, useRef } from 'react'
import { useToast } from '@chakra-ui/core'

import { navigate } from '@reach/router'
import Gallery from './Gallery'
import axios from 'axios'
import cl, { TAG } from './cloudinary'
window.cl = cl

function App() {
  const [resources, setResources] = useState([])
  const resourcesFetched = useRef(false)
  const toast = useToast()


  const fetchCachedData = async () => {
    const cdnResponse = await axios.get(cl.url(`${TAG}.json`, { type: 'list' }))
    if (!resourcesFetched.current) {
      setResources(cdnResponse.data.resources)
    }
  }

  const fetchData = async () => {
    const secret =
      localStorage.secret || prompt('Who is the king of all momes?')
    try {
      const searchResponse = await axios.get(
        `/.netlify/functions/cloudinary?tag=${TAG}&secret=${secret}`,
      )
      setResources(searchResponse.data.resources)
      resourcesFetched.current = true
      localStorage.secret = secret
    } catch (e) {
      toast({ title: 'ERROR!', description: e.message, status: 'error' })
      localStorage.removeItem('secret')
    }
  }

  useEffect(() => {
    // fetchCachedData()
    fetchData()
  }, [])

  function handleThumbClick(thumb) {
    const { public_id } = thumb
    navigate(public_id)
  }
  return (
    <>
      {!resources.length ? (
        'loading'
      ) : (
        <Gallery
          resources={resources}
          onThumbClick={handleThumbClick}
          onUploadComplete={fetchData}
        />
      )}
    </>
  )
}

export default App
